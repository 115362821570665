import React from 'react';
import styled from 'styled-components';
import Button from 'components/Core/Button';
import { Col } from 'components/Core/Grid';
import WebsiteImage from 'components/WebsiteImage';
import {
  getTitle,
  ContentTypeHomeRoute,
  ContentTypeHeadings,
  ctaTypeMap,
} from 'components/Page/Toolbox/utils';

const FeaturedItem = ({ data, type, defaultImage }) => {
  if (type === 'ContentfulWebinar' && data && !data?.onDemandPage) {
    data = new Date(data.webinarDate) > new Date() ? data : null;
  }

  const heading = data
    ? getTitle(data)
    : `Check out all of our ${ContentTypeHeadings[type]?.toLowerCase()}`;

  const ctaLabel = data ? getFeaturedCTALabel(data) : `Explore All ${ContentTypeHeadings[type]}`;

  return (
    <StyledCol xs={24} lg={12}>
      <WebsiteImage {...(data?.featuredImage || defaultImage)} />
      <section>
        {type === 'ContentfulWebinar' && !data?.onDemandPage && <WebinarOverline data={data} />}
        <h4>{heading}</h4>
        <Button type="white" to={data?.seo?.pageUrl || ContentTypeHomeRoute[type]}>
          {ctaLabel}
        </Button>
      </section>
    </StyledCol>
  );
};

const getFeaturedCTALabel = (data) =>
  !data?.onDemandPage ? 'Register Now' : ctaTypeMap[data.__typename];

const WebinarOverline = ({ data }) => (
  <p className="font-sm">
    {data
      ? `UPCOMING WEBINAR - ${data.formattedWebinarDate} PT`.toUpperCase()
      : 'No upcoming webinars'}
  </p>
);

export default FeaturedItem;

const StyledCol = styled(Col)`
  background: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  section {
    padding: 35px;
    width: 100%;
    p {
      width: 100%;
      margin-bottom: 10px;
      color: var(--grey-4);
    }
    h4 {
      width: 100%;
      color: var(--white);
      margin-bottom: 35px;
      @media (max-width: 576px) {
        margin-bottom: 20px;
      }
    }
    button,
    a {
      width: 100%;
      padding: 14px 24px;
    }
    @media (max-width: 576px) {
      padding: 20px;
    }
  }
`;
