import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'components/Core/Grid';
import HyperLink from 'components/Core/HyperLink';
import { getTitle } from 'components/Page/Toolbox/utils';
import toolboxLogos from 'components/Page/Toolbox/data/toolboxLogos';
import WebsiteImage from 'components/WebsiteImage';

const ContentItemList = ({ data }) => {
  const { articlesBlackLogo, podcastBlackLogo, toolsBlackLogo, webinarBlackLogo } = toolboxLogos();

  const contentBlackIconMap = {
    ContentfulGatedContentPage: articlesBlackLogo,
    ContentfulBlog: articlesBlackLogo,
    ContentfulWebinar: webinarBlackLogo,
    ContentfulPodcast: podcastBlackLogo,
    ContentfulTool: toolsBlackLogo,
  };
  return (
    <StyledCol xs={24} lg={12}>
      <Row>
        <h6 className="font-md">Recent</h6>
      </Row>
      {data?.map((x, index) => (
        <React.Fragment key={index}>
          <ContentItem
            icon={contentBlackIconMap[x.__typename]}
            title={getTitle(x)}
            url={x.seo.pageUrl}
          />
          {index !== data.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </StyledCol>
  );
};

const ContentItem = ({ icon, title, url }) => (
  <HyperLink className="content-item-link" href={url}>
    <StyledRow container direction="row" align="center">
      <Col xs={4} md={3}>
        <WebsiteImage image={icon} />
      </Col>
      <Col xs={20} md={21}>
        <h4 className="font-medium">{title}</h4>
      </Col>
    </StyledRow>
  </HyperLink>
);

export default ContentItemList;

const StyledRow = styled(Row)`
  padding: 25px 30px !important;
  @media (max-width: 576px) {
    padding: 20px 10px !important;
  }
  img {
    margin-bottom: 5px;
    width: 32px;
    height: 34px;
  }
`;

const StyledCol = styled(Col)`
  padding: 40px !important;
  @media (max-width: 576px) {
    padding: 20px !important;
  }
`;
