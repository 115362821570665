import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'components/Core/Grid';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import ContentItemList from './ContentItemList';
import FeaturedItem from './FeaturedItem';

const ContentShowcase = ({ entries = [] }) => {
  const sectionHeading = getEntry('heading', entries);
  const defaultFeaturedImage = getEntry('default-image', entries);
  const queries = getEntry('queries', entries)?.components;

  const contentType = queries?.[1]?.executedQuery?.[0]?.__typename;
  const data = queries?.[1]?.executedQuery;
  const featuredItem =
    contentType === 'ContentfulWebinar'
      ? queries?.[0]?.executedQuery.find(
          (webinar) => new Date().toISOString() < webinar?.webinarDate,
        )
      : queries?.[0]?.executedQuery?.[0];

  return (
    <Container>
      <Row container justifyContent="center">
        {sectionHeading && (
          <HeadingCol xs={24}>
            <ContentfulRichText richText={sectionHeading} />
          </HeadingCol>
        )}
        <ContentCol xs={24}>
          <Row container direction="row">
            <ContentItemList data={data} />
            {(featuredItem || defaultFeaturedImage) && (
              <FeaturedItem
                data={featuredItem}
                type={contentType}
                defaultImage={defaultFeaturedImage}
              />
            )}
          </Row>
        </ContentCol>
      </Row>
    </Container>
  );
};

export default ContentShowcase;

const Container = styled.div`
  padding: 50px 28px;
  background: var(--darkest);
  & > div {
    margin: 75px 0px;
    max-width: 1196px;
    width: 100%;
    margin: 0 auto;
  }
  h3 {
    color: var(--white);
  }

  hr {
    border: 1px solid var(--grey-3);
  }

  @media (max-width: 576px) {
    padding: 30px 28px;
  }
`;

const HeadingCol = styled(Col)`
  h3 {
    margin-bottom: 40px;
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }
`;

const ContentCol = styled(Col)`
  & > div {
    background: var(--white);
    @media (max-width: 991px) {
      flex-direction: column-reverse !important;
    }
  }
`;
